export default {
    path: "/system-config",
    meta: {
        title: "系统配置",
        moduleName: "sys-config",
        code: "menu:sys-config",
    },
    children: [
        {
            path: "/system-config/user-management",
            meta: {
                title: "用户管理",
                code: "menu:user-mgr",
                groupName: "perm-mgr",
            },
            component: () => import("@/views/systemConfig/authorityManage/userManage/index.vue"),
        },
        {
            path: "/system-config/role-management",
            meta: {
                title: "角色管理",
                code: "menu:role-mgr",
                groupName: "perm-mgr",
            },
            component: () => import("@/views/systemConfig/authorityManage/roleManage/index.vue"),
        },
        {
            path: "/system-config/post-management",
            meta: {
                title: "岗位管理",
                code: "menu:post-mgr",
                groupName: "perm-mgr",
            },
            component: () => import("@/views/systemConfig/authorityManage/postManage/index.vue"),
        },
        {
            path: "/system-config/department-management",
            meta: {
                title: "部门管理",
                code: "menu:dept-mgr",
                groupName: "perm-mgr",
            },
            component: () =>
                import("@/views/systemConfig/authorityManage/departmentManage/index.vue"),
        },
        {
            path: "/system-config/apk-management",
            meta: {
                title: "apk管理",
                code: "menu:mobile-app-mgr",
                groupName: "perm-mgr",
            },
            component: () => import("@/views/systemConfig/authorityManage/apkManage/index.vue"),
        },

        {
            path: "/system-config/login-log",
            meta: {
                title: "登录日志",
                code: "menu:login-log",
                groupName: "run-monitor",
            },
            component: () => import("@/views/systemConfig/runMonitoring/loginLog/index.vue"),
        },
        {
            path: "/system-config/access-log",
            meta: {
                title: "访问日志",
                code: "menu:access-log",
                groupName: "run-monitor",
            },
            component: () => import("@/views/systemConfig/runMonitoring/accessLog/index.vue"),
        },
        {
            path: "/system-config/operation-log",
            meta: {
                title: "操作日志",
                code: "menu:operation-log",
                groupName: "run-monitor",
            },
            component: () => import("@/views/systemConfig/runMonitoring/operateLog/index.vue"),
        },
    ],
};
