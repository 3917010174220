import axios from "axios";
import { baseURL } from "./config";
import store from "@/store";
import { showMessage } from "@/utils/common";

class HttpRequest {
    constructor(url = baseURL) {
        this.whiteUrl = ["/auth/login"];
        this.baseURL = baseURL;
    }
    getInsideConfig(url) {
        const config = {
            baseURL: `${this.baseURL}/${url.startsWith("/open") ? "" : "web"}`,
            timeout: 30000,
        };
        return config;
    }
    interceptors(instance, options) {
        const token = store.getters["user/token"];
        instance.interceptors.request.use(
            config => {
                let { url } = options;
                if (!this.whiteUrl.includes(url)) config.headers["mes-token"] = token;
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );
        instance.interceptors.response.use(
            ({ data }) => {
                if (data.status !== "SUCCESS") {
                    showMessage("error", data.msg || data.data);
                }
                return data;
            },
            error => {
                if (navigator.onLine) {
                    if (error?.response) {
                        if (error?.response?.status === 401) {
                            store.dispatch({
                                type: "loginOut",
                            });
                            showMessage("error", "登录过期，身份验证失败，请重新登录");
                        } else {
                            showMessage(
                                "error",
                                error?.response?.data?.msg || "服务器错误，请稍后重试"
                            );
                        }
                    } else {
                        showMessage("error", "网络较慢，请求超时，请重试");
                    }
                } else {
                    showMessage("error", "网络未连接");
                }
                return error;
            }
        );
    }
    request(options) {
        let mergeOptions = {
            ...options,
            ...this.getInsideConfig(options.url),
        };
        const instance = axios.create();
        this.interceptors(instance, mergeOptions);
        return instance(mergeOptions);
    }
}

export default HttpRequest;
