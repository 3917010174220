import { createStore } from "vuex";
import createPersistedstate from "vuex-persistedstate";
import { app, appOriginState } from "./modules/app";
import { user, userOriginState } from "./modules/user";
import router from "../router";
import { apiLoginOut } from "@/api";

const store = createStore({
    modules: {
        app,
        user,
    },
    mutations: {
        setLoginOut(state) {
            // state.app = {
            //   ...appOriginState
            // }
            // state.user = {
            //   ...userOriginState
            // }
            state.token = "";
            state.user.userInfo = null;
            state.app.currentModule = 0;
            state.app.menuPermisson = null;
            state.app.btnPerms = null;
            state.app.menus = [];
            state.app.breadcrumb = [];
            state.app.sider = null;
            router.replace("/login");
            location.reload();
        },
    },
    actions: {
        async loginOut({ commit }) {
            let res = await apiLoginOut();
            if (res.status == "SUCCESS") {
                commit("setLoginOut");
            }
        },
    },
    plugins: [
        createPersistedstate({
            key: "sermatec_mes",
            storage: window.localStorage,
            paths: [
                "user.token",
                "user.userInfo",
                "app.currentModule",
                "app.menuPermisson",
                "app.btnPerms",
                "app.menus",
                "app.breadcrumb",
                "app.sider",
            ], //配置需要持久化的数据
        }),
    ],
});

export default store;
