import HttpRequest from "@/utils/request";

const axios = new HttpRequest();

// 版本
const apiGetVersion = () => axios.request({ url: "/open/get-project-info", method: "get" });

const apiLogin = data => axios.request({ url: "/auth/login", method: "post", data });

const apiLoginOut = data => axios.request({ url: "/auth/logout", method: "post", data });
// 系统外重置密码
const apiResetPwd = data => axios.request({ url: "/auth/update-password", method: "post", data });

// 系统内修改密码
const apiModifyPwd = data =>
    axios.request({ url: "/sys-user/update-my-password", method: "post", data });

const apiGetPwdPattern = () => axios.request({ url: "/auth/get-password-pattern", method: "get" });

// 菜单权限  用户基础数据
const apiIndexData = () => axios.request({ url: "/home/index-data", method: "get" });

// 大屏
const apiBigScreenIntegratedPanel = () =>
    axios.request({ url: "/open/mes-stats/get-workshop-board-data", method: "get" });

const apiBigScreenWorkshopPanel = () =>
    axios.request({ url: "/open/mes-stats/get-cj-board-data", method: "get" });

const apiBigScreenExceptionPanel = () =>
    axios.request({ url: "/open/mes-stats/get-exc-board-data", method: "get" });

const apiBigScreenProjectList = () =>
    axios.request({ url: "/open/mes-stats/get-project-infos", method: "get" });

const apiBigScreenWorkOrderProgresses = code =>
    axios.request({
        url: `/open/mes-stats/get-work-order-progresses-by-project-code2?projectCode=${code}`,
        method: "get",
    });

// 首页
const apiHomeIndexData = () => axios.request({ url: "/home/workbench-data", method: "get" });

// 工序流转统计
const apiProcessFlowStatisticsList = data =>
    axios.request({ url: "/mes-work-order-step/find-flow-operation-page", method: "post", data });

const apiProcessFlowStatisticsIndexData = () =>
    axios.request({ url: "/mes-work-order-step/get-flow-query-page-init-data", method: "get" });

const apiProcessFlowStatisticsReworkDetail = id =>
    axios.request({ url: `/mes-work-order-step/get-rework-operation-info/${id}`, method: "get" });

const apiProcessFlowStatisticsCompleteDetail = id =>
    axios.request({ url: `/mes-work-order-step/get-complete-operation-info/${id}`, method: "get" });

// 工单作业
const apiWorkOrderIndexData = () =>
    axios.request({ url: "/mes-work-order/get-search-init-data", method: "get" });

const apiWorkOrderGetReportInfo = id =>
    axios.request({ url: `/mes-work-order/get-report-work-page-init-data/${id}`, method: "get" });

const apiWorkOrderSaveReport = data =>
    axios.request({ url: "/mes-work-report/report-work", method: "post", data });

const apiWorkOrderSaveBatchReport = data =>
    axios.request({ url: "/mes-work-report/report-works", method: "post", data });

const apiWorkOrderTerminated = id =>
    axios.request({ url: `/mes-work-order/terminated/${id}`, method: "post" });

const apiWorkOrderComplete = id =>
    axios.request({ url: `/mes-work-order/completed/${id}`, method: "post" });

const apiWorkOrderSaveProcessComplete = data =>
    axios.request({ url: "/mes-work-order-step/completed", method: "post", data });

const apiWorkOrderSaveProcessRework = data =>
    axios.request({ url: "/mes-work-order-step/rework", method: "post", data });

// 异常事件
const apiAbnormalEventList = data =>
    axios.request({ url: "/mes-exc-report/find-handle-page", method: "post", data });

const apiAbnormalEventIndexData = () =>
    axios.request({ url: "/mes-exc-report/get-add-page-init-data", method: "get" });

const apiAbnormalEventDetail = id =>
    axios.request({ url: `/mes-exc-report/get-info/${id}`, method: "get" });

const apiAbnormalEventHandle = data =>
    axios.request({ url: "/mes-exc-report/exc-handle", method: "post", data });

const apiAbnormalEventReport = data =>
    axios.request({ url: "/mes-exc-report/report", method: "post", data });

// 集成报工
const apiJCWorkReportList = data =>
    axios.request({ url: "/mes-work-report/find-info-page", method: "post", data });

const apiJCWorkReportIndexData = () =>
    axios.request({ url: "/mes-work-report/get-search-page-init-data", method: "get" });

const apiJCWorkReportDetail = id =>
    axios.request({ url: `/mes-work-report/get-info/${id}`, method: "get" });

const apiJCWorkReportAudit = data =>
    axios.request({ url: "/mes-work-report/audit", method: "post", data });

// 车间报工
const apiCJWorkReportList = data =>
    axios.request({ url: "/mes-cj-work-report/find-info-page", method: "post", data });

const apiCJWorkReportIndexData = () =>
    axios.request({ url: "/mes-cj-work-report/get-search-page-init-date", method: "get" });

const apiCJWorkReportDetail = id =>
    axios.request({ url: `/mes-cj-work-report/get-info/${id}`, method: "get" });

// 生产订单
const apiProductOrderList = data =>
    axios.request({ url: "/mes-orders/find-page", method: "post", data });

const apiProductOrderIndexData = () =>
    axios.request({ url: "/mes-orders/get-search-page-init-data", method: "get" });

const apiProductOrderGetUpdateData = id =>
    axios.request({ url: `/mes-orders/get-add-or-edit-page-init-data/${id}`, method: "get" });

const apiProductOrderSavaUpdateData = data =>
    axios.request({ url: "/mes-orders/save-or-update", method: "post", data });

const apiProductOrderDetail = id =>
    axios.request({ url: `/mes-orders/get-info/${id}`, method: "get" });

const apiProductOrderWorkOrderInfo = id =>
    axios.request({ url: `/mes-orders/get-add-work-order-page-init-data/${id}`, method: "get" });

const apiProductOrderIssue = (data, type) =>
    axios.request({
        url: `/mes-orders/generate-${type == "INTEGRATED" ? "" : "cj-"}work-orders`,
        method: "post",
        data,
    });

const apiProductOrderDelete = data =>
    axios.request({ url: "/mes-orders/delete-by-ids", method: "post", data });

const apiProductOrderComplete = id =>
    axios.request({ url: `/mes-orders/completed/${id}`, method: "post" });

const apiProductOrderTerminate = id =>
    axios.request({ url: `/mes-orders/terminated/${id}`, method: "post" });

// 集成工单
const apiProductWorkOrderList = data =>
    axios.request({ url: "/mes-work-order/find-page", method: "post", data });

const apiProductWorkOrderIndexData = id =>
    axios.request({ url: `/mes-work-order/get-search-init-data/${id}`, method: "get" });

const apiProductWorkOrderDetail = id =>
    axios.request({ url: `/mes-work-order/get-info/${id}`, method: "get" });

const apiProductWorkOrderGetUpdateData = id =>
    axios.request({ url: `/mes-work-order/get-add-or-edit-page-init-data/${id}`, method: "get" });

const apiProductWorkOrderSavaUpdateData = (data, type) =>
    axios.request({
        url: `/mes-work-order/save-or-update`,
        method: "post",
        data,
    });

const apiProductWorkOrderDelete = data =>
    axios.request({ url: "/mes-work-order/delete-by-ids", method: "post", data });

const apiProductWorkOrderExport = data =>
    axios.request({ url: "/mes-work-report/export-list", method: "post", data });

const apiProductWorkOrderPutinto = data =>
    axios.request({ url: "/mes-work-order/producing", method: "post", data });

const apiProductWorkOrderBatchPutinto = data =>
    axios.request({ url: "/mes-work-order/batch-producing", method: "post", data });

// 额外工时统计
const apiExtraWorkHoursList = data =>
    axios.request({
        url: "/mes-other-work-report/find-page",
        method: "post",
        data,
    });

// 额外工时核对
const apiCheckWorkHoursList = data =>
    axios.request({
        url: "/mes-work-order/find-contains-working-hour-info-page",
        method: "post",
        data,
    });

// 车间工单
const apiCJWorkOrderList = data =>
    axios.request({ url: "/mes-cj-work-order/find-page", method: "post", data });

const apiCJWorkOrderIndexData = id =>
    axios.request({ url: `/mes-cj-work-order/get-search-init-data/${id}`, method: "get" });

const apiCJWorkOrderDetail = id =>
    axios.request({ url: `/mes-cj-work-order/get-deep-info/${id}`, method: "get" });

const apiCJWorkOrderGetUpdateData = id =>
    axios.request({
        url: `/mes-cj-work-order/get-add-or-edit-page-init-data/${id}`,
        method: "get",
    });

const apiCJWorkOrderSavaUpdateData = data =>
    axios.request({
        url: `/mes-cj-work-order/save-or-update`,
        method: "post",
        data,
    });

const apiCJWorkOrderDelete = data =>
    axios.request({ url: "/mes-cj-work-order/delete-by-ids", method: "post", data });

const apiCJWorkOrderExport = data =>
    axios.request({ url: "/mes-cj-work-report/export-list", method: "post", data });

const apiCJWorkOrderPutinto = data =>
    axios.request({ url: "/mes-cj-work-order/producing", method: "post", data });

const apiCJWorkOrderCompleted = id =>
    axios.request({ url: `/mes-cj-work-order/completed/${id}`, method: "post" });

const apiCJWorkOrderTerminated = id =>
    axios.request({ url: `/mes-cj-work-order/terminated/${id}`, method: "post" });

const apiCjWorkOrderGetReportInfo = id =>
    axios.request({
        url: `/mes-cj-work-order/get-report-work-page-init-data/${id}`,
        method: "get",
    });

const apiCjWorkOrderSaveReport = data =>
    axios.request({
        url: "/mes-cj-work-report/report-work",
        method: "post",
        data,
    });

const apiCjWorkOrderMaterialBindIndexData = id =>
    axios.request({
        url: `/mes-cj-work-order/get-material-bind-page-init-data/${id}`,
        method: "get",
    });

const apiCjWorkOrderSaveMaterialBind = data =>
    axios.request({
        url: "/mes-cj-work-order-material-bind/material-bind",
        method: "post",
        data,
    });

// 工具 条码查询
const apiSearchDetailCode = code =>
    axios.request({
        url: `/item-search/get-some-item-by-barcode/${code}`,
        method: "get",
    });

// 车间产成品
const apiCjFinishProduct = data =>
    axios.request({
        url: "/mes-cj-work-order-product/find-page",
        method: "post",
        data,
    });

const apiCjFinishProductDetailByCode = barcode =>
    axios.request({
        url: `/mes-cj-work-order-product/get-by-barcode/${barcode}`,
        method: "get",
    });

const apiCjFinishProductDetailByWorkordrIdAndBarCode = (workorderId, barcode) =>
    axios.request({
        url: `/mes-cj-work-order-product/get-product/work-order-id/${workorderId}/barcode/${barcode}`,
        method: "get",
    });

const apiCjFinishProductDetailById = id =>
    axios.request({
        url: `/mes-cj-work-order-product/get-info/${id}`,
        method: "get",
    });

const apiCjFinishProductComplete = data =>
    axios.request({
        url: "/mes-cj-work-order-product/complete",
        method: "post",
        data,
    });

const apiCjFinishProductCompleteList = data =>
    axios.request({
        url: "/mes-cj-work-order-product/find-complete-operation-page",
        method: "post",
        data,
    });

// 物料 入库单
const apiMaterialList = data =>
    axios.request({
        url: "/erp-wv/find-page",
        method: "post",
        data,
    });

const apiMaterialExportList = data =>
    axios.request({
        url: "/erp-wv/find-list",
        method: "post",
        data,
    });

const apiMaterialDetailById = id =>
    axios.request({
        url: `/erp-wv/get-info/${id}`,
        method: "get",
    });

const apiMaterialDetail = itemBarcode =>
    axios.request({
        url: `/item-search/get-by-barcode/${itemBarcode}`,
        method: "get",
    });

const apiSaveMaterial = data =>
    axios.request({
        url: "/mes-cj-work-order-material-bind/material-bind",
        method: "post",
        data,
    });

const apiStepWorkRecordList = data =>
    axios.request({
        url: "/mes-cj-work-order-material-bind/find-page",
        method: "post",
        data,
    });

const apiReSetStepWork = id =>
    axios.request({
        url: `/mes-cj-work-order-material-bind/material-bind-reset/${id}`,
        method: "post",
    });

const apiStepWorkDetail = id =>
    axios.request({
        url: `/mes-cj-work-order-material-bind/get-info/${id}`,
        method: "get",
    });

const apiSupplierlMaterialList = data =>
    axios.request({
        url: "/erp-supplier-item/query-page",
        method: "post",
        data,
    });

const apiSupplierlMaterialDetail = itemBarcode =>
    axios.request({
        url: `/erp-supplier-item/get-info/${itemBarcode}`,
        method: "get",
    });

const apiSupplierlMaterialEnterRecordsList = data =>
    axios.request({
        url: "/erp-supplier-item/query-import-operation-page",
        method: "post",
        data,
    });

const apiSupplierlMaterialEnterRecordsInitData = () =>
    axios.request({
        url: "/erp-supplier-item/get-import-operation-search-page-init-data",
        method: "get",
    });

const apiSupplierlMaterialRevokeEnterRecordsByOpreatId = data =>
    axios.request({
        url: `/erp-supplier-item/rollback-by-operation-id?operationId=${data}`,
        method: "post",
    });

const apiSupplierlMaterialRevokeEnterRecordsByMaterialIds = data =>
    axios.request({
        url: "/erp-supplier-item/rollback-by-operation-id-item-ids",
        method: "post",
        data,
    });

// 工序管理
const apiProcessList = data =>
    axios.request({ url: "/bas-work-step/query-page", method: "post", data });

const apiProcessUpdate = data =>
    axios.request({ url: "/bas-work-step/save-or-update", method: "post", data });

const apiProcessDelete = data =>
    axios.request({ url: "/bas-work-step/delete-by-ids", method: "post", data });

const apiProcessEnable = id =>
    axios.request({ url: `/bas-work-step/change-enabled/${id}`, method: "post" });

const apiProcessIndexData = id =>
    axios.request({
        url: `/bas-work-step/get-add-or-edit-init-data${id ? "/" + id : ""}`,
        method: "get",
    });

const apiProcessDetail = id =>
    axios.request({ url: `/bas-work-step/get-info/${id}`, method: "get" });

// 工艺路线管理
const apiRoutingList = data =>
    axios.request({ url: "/bas-process/query-page", method: "post", data });

const apiRoutingUpdate = data =>
    axios.request({ url: "/bas-process/save-or-update", method: "post", data });

const apiRoutingDetail = id => axios.request({ url: `/bas-process/get-info/${id}`, method: "get" });

const apiRoutingSetData = id =>
    axios.request({ url: `/bas-process/get-info/${id}`, method: "get" });

const apiRoutingSaveSet = data =>
    axios.request({ url: "/bas-process/save-process-work-steps", method: "post", data });

const apiRoutingCopy = data =>
    axios.request({ url: "/bas-process/copy-by-id-need-code-and-name/", method: "post", data });

const apiRoutingDelete = data =>
    axios.request({ url: "/bas-process/delete-by-id", method: "post", data });

const apiRoutingEnable = id =>
    axios.request({ url: `/bas-process/change-enabled/${id}`, method: "post" });

const apiRoutingIndexData = id =>
    axios.request({ url: `/bas-process/get-add-or-edit-init-data/${id}`, method: "get" });

// 班组管理
const apiTeamList = data =>
    axios.request({ url: "/bas-produce-team/find-page", method: "post", data });

const apiMemberListChoose = ({ id, data }) =>
    axios.request({ url: `/bas-produce-team/find-can-add-user-page/${id}`, method: "post", data });

const apiChangeTeamEnable = id =>
    axios.request({ url: `/bas-produce-team/change-enabled/${id}`, method: "post" });

const apiTeamDelete = data =>
    axios.request({ url: "/bas-produce-team/delete-by-ids", method: "post", data });

const apiTeamGetInfo = id =>
    axios.request({ url: `/bas-produce-team/get-info/${id}`, method: "get" });

const apiTeamUpdate = data =>
    axios.request({ url: "/bas-produce-team/save-or-update", method: "post", data });

const apiTeamSetLeader = id =>
    axios.request({ url: `bas-produce-team/set-leader/${id}`, method: "post" });

const apiTeamMemberDelete = data =>
    axios.request({ url: "/bas-produce-team/delete-member-by-ids", method: "post", data });

const apiTeamSaveMember = data =>
    axios.request({ url: "/bas-produce-team/generate-members", method: "post", data });

const apiTeamMemberAdd = data =>
    axios.request({ url: "/bas-produce-team/add-member", method: "post", data });

const apiTeamSearchIndexData = () =>
    axios.request({ url: "/bas-produce-team/get-search-page-init-data", method: "get" });

const apiTeamUpdateIndexData = id =>
    axios.request({
        url: `/bas-produce-team/get-add-or-edit-page-init-data${id ? "/" + id : ""}`,
        method: "get",
    });

// 设备管理
const apiDeviceList = data => axios.request({ url: "/bas-device/find-page", method: "post", data });

const apiUpdateDevice = data =>
    axios.request({ url: `/bas-device/${data?.id ? "update" : "save"}`, method: "post", data });

const apiDeleteDevice = id =>
    axios.request({ url: `/bas-device/delete-by-id/${id}`, method: "post" });

const apiChangeDeviceEnable = (id, enabled) =>
    axios.request({ url: `/bas-device/${enabled ? "disabled" : "enabled"}/${id}`, method: "post" });

const apiDeviceGetInfo = id => axios.request({ url: `/bas-device/get-info/${id}`, method: "post" });

const apiUpdateDeviceIndexData = id =>
    axios.request({ url: `/bas-device/get-add-or-edit-page-init-data/${id}`, method: "get" });

// 供应商条码规则管理
const apiSupplierBarcodeRuleList = data =>
    axios.request({ url: "/erp-supplier-item-barcode-rule/find-page", method: "post", data });

const apiUpdateSupplierBarcodeRule = data =>
    axios.request({
        url: `/erp-supplier-item-barcode-rule/${data?.id ? "update" : "save"}`,
        method: "post",
        data,
    });

const apiDeleteSupplierBarcodeRule = id =>
    axios.request({ url: `/erp-supplier-item-barcode-rule/delete-by-id/${id}`, method: "post" });

const apiChangeSupplierBarcodeRuleEnable = (id, enabled) =>
    axios.request({
        url: `/erp-supplier-item-barcode-rule/${enabled ? "disabled" : "enabled"}/${id}`,
        method: "post",
    });

const apiSupplierBarcodeRuleGetInfo = id =>
    axios.request({ url: `/erp-supplier-item-barcode-rule/get-info/${id}`, method: "post" });

const apiUpdateSupplierBarcodeRuleIndexData = id =>
    axios.request({
        url: `/erp-supplier-item-barcode-rule/get-add-or-edit-page-init-data/${id}`,
        method: "get",
    });

// 岗位
const apiPostList = data => axios.request({ url: "/sys-post/find-page", method: "post", data });

const apiUpdatePost = data =>
    axios.request({ url: "/sys-post/save-or-update", method: "post", data });

const apiDeletePost = data =>
    axios.request({ url: "/sys-post/delete-by-ids", method: "post", data });

const apiChangePostEnable = id =>
    axios.request({ url: `/sys-post/change-enabled/${id}`, method: "post" });

const apiPostIndexData = id =>
    axios.request({ url: `/sys-post/get-add-or-edit-init-data/${id}`, method: "get" });

// 部门
const apiDeptList = data => axios.request({ url: "/sys-dept/find-page", method: "post", data });

const apiUpdateDept = data =>
    axios.request({ url: "/sys-dept/save-or-update", method: "post", data });

const apiDeleteDept = data =>
    axios.request({ url: "/sys-dept/delete-by-ids", method: "post", data });

const apiDeptEnable = id =>
    axios.request({ url: `/sys-dept/change-enabled/${id}`, method: "post" });

const apiDeptaTree = () => axios.request({ url: "/sys-dept/get-tree-info", method: "get" });

const apiDeptIndexData = id =>
    axios.request({ url: `/sys-dept/get-add-or-edit-init-data/${id}`, method: "get" });

// 用户
const apiUserList = data => axios.request({ url: "/sys-user/find-page", method: "post", data });

const apiUpdateUser = data =>
    axios.request({ url: "/sys-user/save-or-update", method: "post", data });

const apiDeleteUser = data =>
    axios.request({ url: "/sys-user/delete-by-ids", method: "post", data });

const apiUserEnable = id =>
    axios.request({ url: `/sys-user/change-enabled/${id}`, method: "post" });

const apiResetPassword = id =>
    axios.request({ url: `/sys-user/reset-password/${id}`, method: "post" });

const apiSearchUserIndexData = id =>
    axios.request({ url: "/sys-user/get-search-init-data", method: "get" });

const apiUpdateUserIndexData = id =>
    axios.request({ url: `/sys-user/get-add-or-edit-init-data/${id}`, method: "get" });

// 角色
const apiRoleList = data => axios.request({ url: "/sys-role/find-page", method: "post", data });

const apiSavePerm = data =>
    axios.request({ url: "/sys-role/save-role-source-perm", method: "post", data });

const apiUpdateRole = data =>
    axios.request({ url: "/sys-role/save-or-update", method: "post", data });

const apiDeleteRole = data =>
    axios.request({ url: "/sys-role/delete-by-ids", method: "post", data });

const apiRoleEnable = id =>
    axios.request({ url: `/sys-role/change-enabled/${id}`, method: "post" });

const apiRoleIndexData = roleId =>
    axios.request({
        url: `/sys-role/get-authorize-source-perm-init-data/${roleId}`,
        method: "get",
    });

// app apk管理
const apiApkList = data =>
    axios.request({ url: "/sys-mobile-app/find-page", method: "post", data });

const apiApkAdd = data => axios.request({ url: "/sys-mobile-app/save", method: "post", data });

const apiApkDelete = id => axios.request({ url: `/sys-mobile-app/delete/${id}`, method: "post" });

const apiApkPublish = id => axios.request({ url: `/sys-mobile-app/publish/${id}`, method: "post" });

// 登录日志
const apiLoginLog = data =>
    axios.request({ url: "/log/find-user-login-page", method: "post", data });

// 访问日志
const apiAccessLog = data => axios.request({ url: "/log/find-access-page", method: "post", data });

const apiSaveAccessPage = pageName =>
    axios.request({
        url: `/log/save-access-log?accessPage=${encodeURIComponent(pageName)}`,
        method: "post",
    });

// 操作日志
const apiOperationLog = data =>
    axios.request({ url: "/log/find-operate-page", method: "post", data });

// 数据字典
const apiDataDictionaryList = data =>
    axios.request({ url: "/bas-dictionary/find-list", method: "post", data });

const apiDataDictionaryUpdate = data =>
    axios.request({ url: "/bas-dictionary/update", method: "post", data });

const apiDataDictionarySave = data =>
    axios.request({ url: "/bas-dictionary/save", method: "post", data });

const apiDataDictionaryMoveUp = id =>
    axios.request({ url: `/bas-dictionary/move-up/${id}`, method: "post" });

const apiDataDictionaryMoveDown = id =>
    axios.request({ url: `/bas-dictionary/move-down/${id}`, method: "post" });

const apiDataDictionaryDelete = data =>
    axios.request({ url: "/bas-dictionary/delete-by-ids", method: "post", data });

const apiDataDictionaryEnable = id =>
    axios.request({ url: `/bas-dictionary/change-enabled/${id}`, method: "post" });

const apiDataDictionaryDetail = id =>
    axios.request({ url: `/bas-dictionary/get-info/${id}`, method: "get" });

const apiDataDictionaryTypes = () =>
    axios.request({ url: "/bas-dictionary/get-dir-tree-list", method: "get" });

// 生产单元
const apiProductUnitList = data =>
    axios.request({ url: "/bas-production-unit/find-page", method: "post", data });

const apiProductUnitUpdate = data =>
    axios.request({ url: "/bas-production-unit/update", method: "post", data });

const apiProductUnitSave = data =>
    axios.request({ url: "/bas-production-unit/save", method: "post", data });

const apiProductUnitIndexData = id =>
    axios.request({
        url: `/bas-production-unit/get-add-or-edit-page-init-data/${id}`,
        method: "get",
    });

const apiProductUnitDetail = id =>
    axios.request({ url: `/bas-production-unit/get-info/${id}`, method: "get" });

const apiProductUnitDelete = data =>
    axios.request({ url: "/bas-production-unit/delete-by-ids", method: "post", data });

const apiProductUnitEnable = id =>
    axios.request({ url: `/bas-production-unit/change-enabled/${id}`, method: "post" });

const apiProductUnitTree = data =>
    axios.request({ url: "/bas-production-unit/get-tree-info", method: "post", data });

const apiProductUnitEffectTree = data =>
    axios.request({ url: "/bas-production-unit/get-active-tree-info", method: "post", data });

// 存货档案
const apiInventoryFileList = data =>
    axios.request({ url: "/erp-bas-item/find-page", method: "post", data });

const apiInventoryFileCategory = () =>
    axios.request({ url: "/erp-bas-item/get-category-tree-list", method: "get" });

// 销售订单
const apiSaleOrderList = data =>
    axios.request({ url: "/erp-sales-order/find-page", method: "post", data });

// 自动生成code
const apiAutoCreateCode = businessType =>
    axios.request({ url: `/sys-auto-generate-code/generate/${businessType}`, method: "get" });

// 数据统计
const apiJCStepWorkHoursList = data =>
    axios.request({ url: "/mes-stats/find-project-step-work-hour-page", method: "post", data });

const apiProjectWorkHoursList = data =>
    axios.request({ url: "/mes-stats/find-project-work-hour-page", method: "post", data });

export {
    apiGetVersion,
    apiLogin,
    apiLoginOut,
    apiResetPwd,
    apiModifyPwd,
    apiGetPwdPattern,
    apiIndexData,
    // 大屏
    apiBigScreenIntegratedPanel,
    apiBigScreenWorkshopPanel,
    apiBigScreenExceptionPanel,
    apiBigScreenProjectList,
    apiBigScreenWorkOrderProgresses,
    // 首页
    apiHomeIndexData,
    // 工序流转统计
    apiProcessFlowStatisticsList,
    apiProcessFlowStatisticsIndexData,
    apiProcessFlowStatisticsReworkDetail,
    apiProcessFlowStatisticsCompleteDetail,
    // 工单作业
    apiWorkOrderIndexData,
    apiWorkOrderGetReportInfo,
    apiWorkOrderSaveReport,
    apiWorkOrderSaveBatchReport,
    apiWorkOrderTerminated,
    apiWorkOrderComplete,
    apiWorkOrderSaveProcessComplete,
    apiWorkOrderSaveProcessRework,
    // 异常事件
    apiAbnormalEventList,
    apiAbnormalEventIndexData,
    apiAbnormalEventDetail,
    apiAbnormalEventHandle,
    apiAbnormalEventReport,
    // 集成报工
    apiJCWorkReportList,
    apiJCWorkReportIndexData,
    apiJCWorkReportDetail,
    apiJCWorkReportAudit,

    // 车间报工
    apiCJWorkReportList,
    apiCJWorkReportIndexData,
    apiCJWorkReportDetail,

    // 集成生产工单
    apiProductWorkOrderList,
    apiProductWorkOrderIndexData,
    apiProductWorkOrderDetail,
    apiProductWorkOrderGetUpdateData,
    apiProductWorkOrderSavaUpdateData,
    apiProductWorkOrderDelete,
    apiProductWorkOrderPutinto,
    apiProductWorkOrderBatchPutinto,
    // 额外工时统计
    apiExtraWorkHoursList,
    // 额外工时核对
    apiCheckWorkHoursList,
    // 车间工单
    apiCJWorkOrderList,
    apiCJWorkOrderIndexData,
    apiCJWorkOrderDetail,
    apiCJWorkOrderGetUpdateData,
    apiCJWorkOrderSavaUpdateData,
    apiCJWorkOrderDelete,
    apiCJWorkOrderExport,
    apiCJWorkOrderPutinto,
    apiCJWorkOrderCompleted,
    apiCJWorkOrderTerminated,
    apiCjWorkOrderGetReportInfo,
    apiCjWorkOrderSaveReport,
    apiCjWorkOrderMaterialBindIndexData,
    apiCjWorkOrderSaveMaterialBind,

    // 工具 条码查询
    apiSearchDetailCode,

    // 车间产成品
    apiCjFinishProduct,
    apiCjFinishProductDetailByCode,
    apiCjFinishProductDetailByWorkordrIdAndBarCode,
    apiCjFinishProductDetailById,
    apiCjFinishProductComplete,
    apiCjFinishProductCompleteList,

    //  物料
    apiMaterialList,
    apiMaterialExportList,
    apiMaterialDetailById,
    apiMaterialDetail,
    apiSaveMaterial,
    apiStepWorkRecordList,
    apiReSetStepWork,
    apiStepWorkDetail,
    apiSupplierlMaterialList,
    apiSupplierlMaterialDetail,
    apiSupplierlMaterialEnterRecordsList,
    apiSupplierlMaterialEnterRecordsInitData,
    apiSupplierlMaterialRevokeEnterRecordsByOpreatId,
    apiSupplierlMaterialRevokeEnterRecordsByMaterialIds,

    // 生产订单
    apiProductOrderList,
    apiProductOrderIndexData,
    apiProductOrderGetUpdateData,
    apiProductOrderSavaUpdateData,
    apiProductOrderDetail,
    apiProductOrderWorkOrderInfo,
    apiProductOrderIssue,
    apiProductOrderDelete,
    apiProductOrderComplete,
    apiProductWorkOrderExport,
    apiProductOrderTerminate,

    // 工序管理
    apiProcessList,
    apiProcessUpdate,
    apiProcessDelete,
    apiProcessEnable,
    apiProcessIndexData,
    apiProcessDetail,
    // 工序路线
    apiRoutingList,
    apiRoutingUpdate,
    apiRoutingDetail,
    apiRoutingSetData,
    apiRoutingSaveSet,
    apiRoutingCopy,
    apiRoutingEnable,
    apiRoutingDelete,
    apiRoutingIndexData,
    // 班组
    apiTeamList,
    apiMemberListChoose,
    apiChangeTeamEnable,
    apiTeamDelete,
    apiTeamGetInfo,
    apiTeamUpdate,
    apiTeamSetLeader,
    apiTeamMemberDelete,
    apiTeamSaveMember,
    apiTeamMemberAdd,
    apiTeamSearchIndexData,
    apiTeamUpdateIndexData,
    // 设备管理
    apiDeviceList,
    apiUpdateDevice,
    apiDeleteDevice,
    apiChangeDeviceEnable,
    apiDeviceGetInfo,
    apiUpdateDeviceIndexData,
    // 供应商条码规则管理
    apiSupplierBarcodeRuleList,
    apiUpdateSupplierBarcodeRule,
    apiDeleteSupplierBarcodeRule,
    apiChangeSupplierBarcodeRuleEnable,
    apiSupplierBarcodeRuleGetInfo,
    apiUpdateSupplierBarcodeRuleIndexData,
    // 岗位
    apiPostList,
    apiUpdatePost,
    apiDeletePost,
    apiChangePostEnable,
    apiPostIndexData,
    // 部门
    apiDeptList,
    apiUpdateDept,
    apiDeleteDept,
    apiDeptEnable,
    apiDeptaTree,
    apiDeptIndexData,
    // 用户
    apiUserList,
    apiUpdateUser,
    apiDeleteUser,
    apiUserEnable,
    apiResetPassword,
    apiSearchUserIndexData,
    apiUpdateUserIndexData,
    // 角色
    apiRoleList,
    apiSavePerm,
    apiUpdateRole,
    apiDeleteRole,
    apiRoleEnable,
    apiRoleIndexData,
    // app apk管理
    apiApkList,
    apiApkAdd,
    apiApkDelete,
    apiApkPublish,
    // 登录日志
    apiLoginLog,
    // 访问日志
    apiAccessLog,
    apiSaveAccessPage,
    // 操作日志
    apiOperationLog,

    // 数据字典
    apiDataDictionaryList,
    apiDataDictionaryUpdate,
    apiDataDictionarySave,
    apiDataDictionaryMoveUp,
    apiDataDictionaryMoveDown,
    apiDataDictionaryDelete,
    apiDataDictionaryEnable,
    apiDataDictionaryDetail,
    apiDataDictionaryTypes,

    // 生产单元
    apiProductUnitList,
    apiProductUnitIndexData,
    apiProductUnitUpdate,
    apiProductUnitSave,
    apiProductUnitDetail,
    apiProductUnitDelete,
    apiProductUnitEnable,
    apiProductUnitTree,
    apiProductUnitEffectTree,

    // 存货档案
    apiInventoryFileList,
    apiInventoryFileCategory,

    // 销售订单
    apiSaleOrderList,

    // 自动生成
    apiAutoCreateCode,

    // 数据统计
    apiJCStepWorkHoursList,
    apiProjectWorkHoursList,
};
