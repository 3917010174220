import productManage from "./productManage";
import baseInfo from "./baseInfo";
import storageManage from "./storageManage";
import qualityManage from "./qualityManage";
import systemConfiguration from "./systemConfiguration";

const systemConfigGroup = [
    {
        title: "权限管理",
        icon: "LockOutlined",
        code: "menu:perm-mgr",
        groupName: "perm-mgr",
    },
    {
        title: "运行监控",
        icon: "HistoryOutlined",
        code: "menu:running-monitor",
        groupName: "run-monitor",
    },
];

const productManageGroup = [
    {
        title: "生产规划",
        icon: "RiseOutlined",
        code: "menu:production-plan",
        groupName: "product-plan",
    },
    {
        title: "工单作业",
        icon: "HomeOutlined",
        code: "menu:works-group",
        groupName: "workorder-operate",
    },
    {
        title: "生产管控",
        icon: "PartitionOutlined",
        code: "menu:production-mgr",
        groupName: "product-control",
    },
    {
        title: "车间制品管理",
        icon: "GoldOutlined",
        code: "menu:cj-product-mgr",
        groupName: "workshop-product-manage",
    },
    {
        title: "数据统计",
        icon: "PieChartOutlined",
        code: "menu:statistics",
        groupName: "data-statistics",
    },
];

const baseInfoGroup = [
    {
        title: "生产信息",
        icon: "FileDoneOutlined",
        code: "menu:production-info",
        groupName: "product-info",
    },
    {
        title: "质量信息",
        icon: "VerifiedOutlined",
        code: "menu:quality-info",
        groupName: "quality-info",
    },
    {
        title: "通用数据",
        icon: "HistoryOutlined",
        code: "menu:general-data",
        groupName: "general-data", 
    },
];

const storageManageGroup = [
    {
        title: "送检单管理",
        icon: "ImportOutlined",
        code: "menu:storage-send-check-list-mgr",
        groupName: "SendCheckOrder",
    },
    {
        title: "入库管理",
        icon: "ImportOutlined",
        code: "menu:wv-mgr",
        groupName: "warehousingManage",
    },
    {
        title: "物料信息录入",
        icon: "DownloadOutlined",
        code: "menu:supplier-item-mgr",
        groupName: "materialInfoImport",
    },
];

const qualityManageGroup = [
    {
        title: "送检单管理",
        icon: "ImportOutlined",
        code: "menu:qc-send-check-list-mgr",
        groupName: "SendCheckOrder",
    },
    {
        title: "检验单管理",
        icon: "DownloadOutlined",
        code: "menu:check-list-mgr",
        groupName: "checkOrderManage",
    },
];

const addRoutesToGroups = (groups, _module) => {
    groups.forEach(item => {
        item.children = _module.children
            .map(route => {
                if (item.groupName == route.meta.groupName) {
                    return route;
                }
            })
            .filter(route => route);
    });
    return groups;
};

export default [
    addRoutesToGroups(productManageGroup, productManage),
    addRoutesToGroups(qualityManageGroup, qualityManage),
    addRoutesToGroups(storageManageGroup, storageManage),
    addRoutesToGroups(baseInfoGroup, baseInfo),
    addRoutesToGroups(systemConfigGroup, systemConfiguration),
];
