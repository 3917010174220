import store from "@/store";
import setAsyncRoutes from "./async";
export default function patch(router) {
    router
        .isReady()
        .then(() => {
            setAsyncRoutes(store.getters["app/menuPermisson"]);
        })
        .catch(() => {});
}
