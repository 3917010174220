import { createRouter, createWebHistory } from "vue-router";
import permit from "./permit";
import patch from "./patch";

const routes = [
    {
        path: "/login",
        name: "login",
        component: _ => import("@/views/login/index.vue"),
    },
    {
        path: "/",
        name: "layout",
        component: _ => import("@/views/layout/index.vue"),
        children: [
            {
                path: "/403",
                component: _ => import("@/views/403/index.vue"),
            },
            {
                path: "/index-workbench",
                component: _ => import("@/views/index/index.vue"),
            },
        ],
    },
    {
        path: "/large-screen/integrated-production-panel",
        component: () => import("@/views/largeScreen/integratedProductionPanel/index.vue"),
    },
    {
        path: "/large-screen/workshop-production-panel",
        component: () => import("@/views/largeScreen/workshopProductionPanel/index.vue"),
    },
    {
        path: "/large-screen/exception-panel",
        component: () => import("@/views/largeScreen/exceptionPanel/index.vue"),
    },
    {
        path: "/large-screen/project-progress-panel",
        component: () => import("@/views/largeScreen/projectProgressPanel/index.vue"),
    },
    {
        path: "/product-manage/step-homework",
        component: () =>
            import(
                "@/views/productManage/workorderOperate/workshopWorkorder/stepHomework/index.vue"
            ),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

permit(router);
patch(router);

export default router;
