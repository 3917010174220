export default {
    path: "/base-data",
    meta: {
        title: "基础数据",
        moduleName: "base-info",
        code: "menu:production-bas-info",
    },
    children: [
        {
            path: "/base-data/workshop-model",
            meta: {
                title: "生产车间建模",
                code: "menu:workshop-model",
                groupName: "product-info",
            },
            component: () => import("@/views/baseData/productInfo/workshopModel/index.vue"),
        },
        {
            path: "/base-data/process-manage",
            meta: {
                title: "工序管理",
                code: "menu:work-step",
                groupName: "product-info",
            },
            component: () => import("@/views/baseData/productInfo/processManage/index.vue"),
        },
        {
            path: "/base-data/routing-manage",
            meta: {
                title: "工艺路线管理",
                code: "menu:process",
                groupName: "product-info",
            },
            component: () => import("@/views/baseData/productInfo/routingManage/index.vue"),
        },
        {
            path: "/base-data/team-manage",
            meta: {
                title: "班组管理",
                code: "menu:produce-team",
                groupName: "product-info",
            },
            component: () => import("@/views/baseData/productInfo/teamManage/index.vue"),
        },
        {
            path: "/base-data/device-management",
            meta: {
                title: "设备管理",
                code: "menu:bas-device",
                groupName: "product-info",
            },
            component: () => import("@/views/baseData/productInfo/deviceManagement/index.vue"),
        },
        {
            path: "/base-data/product-plan",
            meta: {
                title: "标准产品方案管理",
                code: "menu:bas-standard-product-plan",
                groupName: "product-info",
            },
            component: () => import("@/views/baseData/productInfo/productPlan/index.vue"),
        },
        {
            path: "/base-data/check-item",
            meta: {
                title: "质检项管理",
                code: "menu:bas-qc-item-mgr",
                groupName: "quality-info",
            },
            component: () => import("@/views/baseData/qualityInfo/checkItem/index.vue"),
        },
        {
            path: "/base-data/check-plan",
            meta: {
                title: "质检方案管理",
                code: "menu:qc-plan-mgr",
                groupName: "quality-info",
            },
            component: () => import("@/views/baseData/qualityInfo/checkPlan/index.vue"),
        },
        {
            path: "/base-data/material-check-team",
            meta: {
                title: "物料质检组管理",
                code: "menu:material-qc-group-mgr",
                groupName: "quality-info",
            },
            component: () => import("@/views/baseData/qualityInfo/materialCheckTeam/index.vue"),
        },
        {
            path: "/base-data/check-tool",
            meta: {
                title: "检具管理",
                code: "menu:bas-check-tool-mgr",
                groupName: "quality-info",
            },
            component: () => import("@/views/baseData/qualityInfo/checkTool/index.vue"),
        },
        {
            path: "/base-data/inventory-file",
            meta: {
                title: "存货档案",
                code: "menu:stock-archives",
                groupName: "general-data",
            },
            component: () => import("@/views/baseData/generalData/inventoryFile/index.vue"),
        },
        {
            path: "/base-data/data-dictionary",
            meta: {
                title: "数据字典",
                code: "menu:sys-dictionary",
                groupName: "general-data",
            },
            component: () => import("@/views/baseData/generalData/dataDictionary/index.vue"),
        },
        {
            path: "/base-data/unit-archives",
            meta: {
                title: "主计量单位档案",
                code: "menu:primary-uom",
                groupName: "general-data",
            },
            component: () => import("@/views/baseData/generalData/unitArchives/index.vue"),
        },
    ],
};
