const appOriginState = {
    currentModule: 0,
    modules: [
        { title: "生产管理", moduleId: 0, code: "menu:production-management" },
        { title: "质量管理", moduleId: 1, code: "menu:qc-mgr" },
        { title: "仓储管理", moduleId: 2, code: "menu:warehouse-mgr" },
        { title: "基础信息", moduleId: 3, code: "menu:production-bas-info" },
        { title: "系统配置", moduleId: 4, code: "menu:sys-config" },
    ],
    menuPermisson: null,
    btnPerms: null,
    breadcrumb: [],
    permCodes: {
        menu: {},
        btn: {},
    },
    sider: {
        menus: [],
        groupName: "",
        firstPath: "",
        currentPath: "",
        collapsed: false,
        firstGroupName: "",
    },
};

const app = {
    namespaced: true,
    state: {
        ...appOriginState,
    },
    getters: {
        currentModule: state => state.currentModule,
        modules: state => state.modules,
        menuPermisson: state => state.menuPermisson,
        btnPerms: state => state.btnPerms,
        breadcrumb: state => state.breadcrumb,
        sider: state => state.sider,
    },
    mutations: {
        setCurrentModule(state, id) {
            state.currentModule = id;
        },
        setMenuPermisson(state, permObj) {
            state.menuPermisson = permObj;
        },
        setBtnPerms(state, data) {
            let btnPerms = {};
            Object.values(data).forEach(page => {
                page.forEach(btn => {
                    btnPerms[btn.code] = btn.enabled;
                });
            });
            state.btnPerms = btnPerms;
        },
        setBreadcrumb(state, path) {
            state.breadcrumb = path;
        },
        setSider(state, data) {
            state.sider = {
                ...state.sider,
                ...data,
            };
        },
    },
    actions: {},
};

export { appOriginState, app };
