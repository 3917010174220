const userOriginState = {
    userInfo: null,
    token: "",
};

const user = {
    namespaced: true,
    state: {
        ...userOriginState,
    },
    getters: {
        userInfo: state => state.userInfo,
        token: state => state.token,
    },
    mutations: {
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo;
        },
        setToken(state, userInfo) {
            state.token = userInfo;
        },
    },
    actions: {},
};

export { userOriginState, user };
