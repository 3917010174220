import store from "../store";
const { getters } = store;

export default function directive(app) {
    app.directive("perm", {
        beforeMount(el, binding, vnode, prevVnode) {
            // 这里还获取不到 el.parentNode 可以用来改自己的样式
        }, // bind
        mounted(el, binding) {
            let flag = false;
            let value = binding.value;
            if (!Array.isArray(value)) {
                value = [value];
            }
            value.find(item => {
                if (getters["app/btnPerms"][item]) flag = true;
            });
            if (!flag) {
                el.parentNode && el.parentNode.removeChild(el);
            }
        }, // inserted
        beforeUpdate() {},
        updated() {}, // componentUpdated
        beforeUnmount() {},
        unmounted() {}, // unbind
    });
}
