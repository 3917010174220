import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import directive from "./directive";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
import "@/styles/antdvReset.less";
import "@/styles/reset.less";
import vue3TreeOrg from "vue3-tree-org";
import "vue3-tree-org/lib/vue3-tree-org.css";
// import print from "vue3-print-nb";

const app = createApp(App);

directive(app);
app.use(store).use(router).use(Antd).use(vue3TreeOrg).mount("#app");
