import baseRoutes from "./baseRoutes";
import router from ".";
import store from "../store";
import { deepClone } from "@/utils/common";

export default function setAsyncRoutes(permObj) {
    if (!Object.keys(permObj).length) return;
    let asyncRoutes = deepClone(baseRoutes);
    asyncRoutes.forEach((groups, groupIndex) => {
        asyncRoutes[groupIndex] = groups.filter((group, groupIndex) => {
            if (permObj[group.code]) {
                groups[groupIndex].children = group.children.filter(route => {
                    if (permObj[route?.meta?.code]) {
                        router.addRoute("layout", route);
                        return route;
                    }
                });
                return group;
            }
        });
    });
    asyncRoutes = asyncRoutes.filter(groups => groups.length != 0);
    router.addRoute("layout", {
        path: "/:pathMatch(.*)*",
        component: _ => import("@/views/404/index.vue"),
    });
    store.commit("app/setSider", {
        menus: asyncRoutes,
    });
    router.replace(router.currentRoute.value.fullPath);
}
