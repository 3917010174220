import { message, Modal, createVNode } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { apiAutoCreateCode } from "@/api";
import moment from "moment";
import store from "@/store";

// info success error warning
const showMessage = (type, content) => {
    return message[type](content);
};

const formateMoment = (timeStamp, format = "YYYY-MM-DD HH:mm:ss") => {
    return moment(timeStamp).format(format);
};

const toType = obj => {
    if (obj == null) return obj + "";
    let type = typeof obj;
    if (/(object|function)/i.test(type)) {
        type = toString.call(obj);
        const [, res = "object"] = type.match(/^\[object (\w+)\]$/) || [];
        return res.toLowerCase();
    }
    return type;
};

const isNumeric = obj => {
    const type = toType(obj);
    return (type === "number" || type === "string") && !isNaN(obj);
};

// info success error warning
const confirmModal = ({
    type = "info",
    title = "系统提示",
    zIndex = 10000,
    content = "",
    onOk = () => {},
}) => {
    Modal.confirm({
        title,
        content,
        cancelText: "取消",
        zIndex,
        okType: type == "info" || type == "success" ? "primary" : "danger",
        okText: "确定",
        onOk,
    });
};

const flatMenuPermisssons = perArr => {
    let permObj = {};
    const cycleCheck = data => {
        data.forEach(item => {
            permObj[item.code] = item.name;
            if (item.children) {
                cycleCheck(item.children);
            }
        });
    };
    cycleCheck(perArr);
    return permObj;
};

const deepClone = function (data = {}) {
    //string,number,bool,null,undefined,symbol
    //object,array,date
    if (data && typeof data === "object") {
        //针对函数的拷贝
        if (typeof data === "function") {
            let tempFunc = data.bind(null);
            tempFunc.prototype = deepClone(data.prototype);
            return tempFunc;
        }
        switch (Object.prototype.toString.call(data)) {
            case "[object String]":
                return data.toString();
            case "[object Number]":
                return Number(data.toString());
            case "[object Boolean]":
                return new Boolean(data.toString());
            case "[object Date]":
                return new Date(data.getTime());
            case "[object Array]":
                let arr = [];
                for (let i = 0; i < data.length; i++) {
                    arr[i] = deepClone(data[i]);
                }
                return arr;

            //js自带对象或用户自定义类实例
            case "[object Object]":
                let obj = {};
                for (let key in data) {
                    //会遍历原型链上的属性方法，可以用hasOwnProperty来控制 （obj.hasOwnProperty(prop)
                    obj[key] = deepClone(data[key]);
                }
                return obj;
        }
    } else {
        //string,number,bool,null,undefined,symbol
        return data;
    }
};

const checkValueIsAvailable = (value, type) => {
    const types = {
        phone: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
        email: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
        chinese: /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g,
        spaceWith: /\s/g, // 包含空格
        spaceStart: /^\s+/g, // 以空格开头
        spaceEnd: /\s+$/g, // 以空格结尾
        spaceAll: /^[ ]*$/, // 全是空格
        spaceStartEnd: /^\S.*\S$|(^\S{0,1}\S$)/, // 以空格开头或结尾
        posPattern: /^\d+$/, // 正整数 包含0
        negPattern: /^-\d+$/, // 负整数 不包含0
        intPattern: /^-\d+$/, // 整数
    };
    return types[type].test(value);
};

const jsonToUrlParams = json => {
    if (toType(json) !== "object") return "";
    const paramsArr = Object.entries(json).filter(
        ([key, value]) => value !== undefined && value !== ""
    );
    if (!paramsArr.length) return "";
    let paramsUrl = paramsArr
        .map(([key, value]) => {
            if (typeof value == "string") value = value.trim();
            return `${key}=${encodeURIComponent(value)}`;
        })
        .join("&");
    return "?" + paramsUrl;
};

const getStatusColor = status => {
    if (["TERMINATED", "REJECTED"].includes(status)) {
        // 终止
        return "#B1B4B9";
    }
    if (["STARTED", "PRODUCING", "IN_PROGRESS"].includes(status)) {
        // 进行中
        return "#169BD5";
    }
    if (["COMPLETE", "COMPLETED", "TERMINATED", "PASSED"].includes(status)) {
        // 已完成
        return "#2BCE1D";
    }
    return "#000"; // 待开始
};

const getAutoCreateCode = async type => {
    let res = await apiAutoCreateCode(type);
    if (res.status === "SUCCESS") {
        return res?.data;
    }
};

const debounce = (fn, wait, immediate) => {
    let timer;
    return function (...args) {
        if (!timer && immediate) {
            fn.call(this, ...args);
        }
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            !immediate && fn.call(this, ...args);
            clearTimeout(timer);
            timer = null;
        }, wait);
    };
};

const thtotate = (fn, wait) => {
    let timer,
        pre = 0;
    return function (...args) {
        let now = +new Date(),
            pass = now - pre; // 过了多少时间
        if (wait < pass) {
            // 间隔时间超过wait 立即执行 包含第一次触发
            fn.call(this, ...args);
            pre = +new Date();
            if (timer) {
                clearTimeout(timer);
                timer = null;
            }
        } else if (!timer) {
            // 间隔时间没超过wait 等待剩余时间后执行
            timer = setTimeout(() => {
                fn.call(this, ...args);
                pre = +new Date();
                clearTimeout(timer);
                timer = null;
            }, wait - pass);
        }
    };
};

export {
    showMessage,
    formateMoment,
    toType,
    isNumeric,
    confirmModal,
    flatMenuPermisssons,
    deepClone,
    checkValueIsAvailable,
    jsonToUrlParams,
    getStatusColor,
    getAutoCreateCode,
    debounce,
    thtotate,
};
