export default {
    path: "/quality-manage",
    meta: {
        title: "质量管理",
        moduleName: "storageManage",
        code: "menu:qc-mgr",
    },
    children: [
        {
            path: "/quality-manage/material-send-checklist",
            meta: {
                title: "来料送检单",
                code: "menu:qc-incoming-material-send-check-list",
                groupName: "SendCheckOrder",
            },
            component: () =>
                import(
                    "@/views/qualityManage/sendCheckOrderManage/materialSendCheckList/index.vue"
                ),
        },
        {
            path: "/quality-manage/incoming-checklist",
            meta: {
                title: "来料检验单管理",
                code: "menu:incoming-material-check-list",
                groupName: "checkOrderManage",
            },
            component: () =>
                import("@/views/qualityManage/checkOrderManage/incomingCheckList/index.vue"),
        },
        {
            path: "/quality-manage/jc-step-checklist",
            meta: {
                title: "集成工序检验单管理",
                code: "menu:jc-step-check-list",
                groupName: "checkOrderManage",
            },
            component: () =>
                import("@/views/qualityManage/checkOrderManage/jcStepCheckList/index.vue"),
        },
        {
            path: "/quality-manage/jc-finish-product-checklist",
            meta: {
                title: "集成成品检验单管理",
                code: "menu:jc-completed-product-check-list",
                groupName: "checkOrderManage",
            },
            component: () =>
                import("@/views/qualityManage/checkOrderManage/jcFinProdCheckList/index.vue"),
        },
    ],
};
