export default {
    path: "/product-manage",
    meta: {
        title: "生产管理",
        moduleName: "product-manage",
        code: "menu:production-management",
    },
    children: [
        {
            path: "/product-manage/product-order",
            meta: {
                title: "生产订单",
                code: "menu:orders",
                groupName: "product-plan",
            },
            component: () => import("@/views/productManage/productPlan/productOrder/index.vue"),
        },
        {
            path: "/product-manage/integrat-product-order",
            meta: {
                title: "集成生产工单",
                code: "menu:work-order",
                groupName: "product-plan",
            },
            component: () =>
                import("@/views/productManage/productPlan/integratProductOrder/index.vue"),
        },
        {
            path: "/product-manage/workshop-product-order",
            meta: {
                title: "车间生产工单",
                code: "menu:workshop-work-order",
                groupName: "product-plan",
            },
            component: () =>
                import("@/views/productManage/productPlan/workshopProductOrder/index.vue"),
        },

        {
            path: "/product-manage/integrat-workorder",
            meta: {
                title: "集成工单作业",
                code: "menu:works",
                groupName: "workorder-operate",
            },
            component: () =>
                import("@/views/productManage/workorderOperate/integratWorkorder/index.vue"),
        },
        {
            path: "/product-manage/workshop-workorder",
            meta: {
                title: "车间工单作业",
                code: "menu:workshop-works",
                groupName: "workorder-operate",
            },
            component: () =>
                import("@/views/productManage/workorderOperate/workshopWorkorder/index.vue"),
        },

        {
            path: "/product-manage/abnormal-event",
            meta: {
                title: "异常事件",
                code: "menu:exception_event",
                groupName: "product-control",
            },
            component: () => import("@/views/productManage/productControl/abnormalEvent/index.vue"),
        },
        {
            path: "/product-manage/integrat-report-manage",
            meta: {
                title: "集成报工管理",
                code: "menu:work-record-mgr",
                groupName: "product-control",
            },
            component: () =>
                import("@/views/productManage/productControl/integratReportManage/index.vue"),
        },
        {
            path: "/product-manage/workshop-finished-product-manage",
            meta: {
                title: "车间产成品管理",
                code: "menu:workshop-finished-product-mgr",
                groupName: "workshop-product-manage",
            },
            component: () =>
                import(
                    "@/views/productManage/workshopProductManage/workshopFinishedProductManage/index.vue"
                ),
        },
        {
            path: "/product-manage/workshop-wip-manage",
            meta: {
                title: "车间在制品管理",
                code: "menu:workshop-producing-product-mgr",
                groupName: "workshop-product-manage",
            },
            component: () =>
                import("@/views/productManage/workshopProductManage/workshopWipManage/index.vue"),
        },
        {
            path: "/product-manage/pack-product-manage",
            meta: {
                title: "Pack制品管理",
                code: "menu:pack-line-product-mgr",
                groupName: "workshop-product-manage",
            },
            component: () =>
                import("@/views/productManage/workshopProductManage/packProductManage/index.vue"),
        },
        {
            path: "/product-manage/workshop-report-manage",
            meta: {
                title: "车间报工管理",
                code: "menu:workshop-work-record-mgr",
                groupName: "product-control",
            },
            component: () =>
                import("@/views/productManage/productControl/workshopReportManage/index.vue"),
        },
        {
            path: "/product-manage/jc-report-statistics",
            meta: {
                title: "集成报工统计",
                code: "menu:work-record-stat",
                groupName: "data-statistics",
            },
            component: () =>
                import("@/views/productManage/dataStatistics/jcReportStatistics/index.vue"),
        },
        {
            path: "/product-manage/jc-extra-workhours-statistics",
            meta: {
                title: "集成额外工时统计",
                code: "menu:jc-work-other-report-stat",
                groupName: "data-statistics",
            },
            component: () =>
                import("@/views/productManage/dataStatistics/jcExtraWorkHoursStatistics/index.vue"),
        },
        {
            path: "/product-manage/jc-step-workhours-report",
            meta: {
                title: "集成工序工时报表",
                code: "menu:integrated-operations-hour-reports",
                groupName: "data-statistics",
            },
            component: () =>
                import("@/views/productManage/dataStatistics/jcStepWorkhoursReport/index.vue"),
        },
        {
            path: "/product-manage/jc-workhours-check-report",
            meta: {
                title: "集成工单工时核对报表",
                code: "menu:jc-work-order-working-hours-stat",
                groupName: "data-statistics",
            },
            component: () =>
                import("@/views/productManage/dataStatistics/jcWorkhoursCheckReport/index.vue"),
        },
        {
            path: "/product-manage/workshop-report-statistics",
            meta: {
                title: "车间报工统计",
                code: "menu:workshop-work-record-stat",
                groupName: "data-statistics",
            },
            component: () =>
                import("@/views/productManage/dataStatistics/workshopReportStatistics/index.vue"),
        },
        {
            path: "/product-manage/jc-abnormal-workhours-statistics",
            meta: {
                title: "集成异常工时统计",
                code: "menu:exception-working-hours-stat",
                groupName: "data-statistics",
            },
            component: () =>
                import(
                    "@/views/productManage/dataStatistics/jcAbnormalWorkhoursStatistics/index.vue"
                ),
        },
        {
            path: "/product-manage/workshop-abnormal-hours-statistics",
            meta: {
                title: "车间异常工时统计",
                code: "menu:cj-exception-working-hours-stat",
                groupName: "data-statistics",
            },
            component: () =>
                import(
                    "@/views/productManage/dataStatistics/workshopAbnormalHoursStatistics/index.vue"
                ),
        },
        {
            path: "/product-manage/project-comprehensive-workhours-report",
            meta: {
                title: "项目综合工时报表",
                code: "menu:comprehensive-work-reports-for-projects",
                groupName: "data-statistics",
            },
            component: () =>
                import("@/views/productManage/dataStatistics/projectWorkhoursReport/index.vue"),
        },
    ],
};
