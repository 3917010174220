export default {
    path: "/storage-manage",
    meta: {
        title: "仓储管理",
        moduleName: "storageManage",
        code: "menu:warehouse-mgr",
    },
    children: [
        {
            path: "/storage-manage/material-send-checklist",
            meta: {
                title: "来料送检单",
                code: "menu:storage-incoming-material-send-check-list",
                groupName: "SendCheckOrder",
            },
            component: () => import("@/views/storageManage/sendCheckOrderManage/index.vue"),
        },
        {
            path: "/storage-manage/purchase-receipt-doc",
            meta: {
                title: "采购入库单列表",
                code: "menu:wv-mgr",
                groupName: "warehousingManage",
            },
            component: () =>
                import("@/views/storageManage/warehousingManage/purchaseReceiptDoc/index.vue"),
        },
        {
            path: "/storage-manage/batch-barcode-entry",
            meta: {
                title: "批次条码录入",
                code: "menu:supplier-item-mgr-1",
                groupName: "materialInfoImport",
            },
            component: () =>
                import("@/views/storageManage/materialinfoImport/batchBarcodeEntry/index.vue"),
        },
        {
            path: "/storage-manage/serial-number-barcode-entry",
            meta: {
                title: "流水号条码录入",
                code: "menu:supplier-item-mgr-2",
                groupName: "materialInfoImport",
            },
            component: () =>
                import(
                    "@/views/storageManage/materialinfoImport/serialNumberBarcodeEntry/index.vue"
                ),
        },
        {
            path: "/storage-manage/single-barcode-entry",
            meta: {
                title: "单件条码录入",
                code: "menu:supplier-item-mgr-3",
                groupName: "materialInfoImport",
            },
            component: () =>
                import("@/views/storageManage/materialinfoImport/singleBarcodeEntry/index.vue"),
        },
        {
            path: "/storage-manage/enter-records",
            meta: {
                title: "录入记录",
                code: "menu:supplier-item-mgr-4",
                groupName: "materialInfoImport",
            },
            component: () =>
                import("@/views/storageManage/materialinfoImport/enterRecords/index.vue"),
        },
        {
            path: "/storage-manage/supplier-barcode-rule-management",
            meta: {
                title: "供应商条码规则管理",
                code: "menu:supplier-item-barcode-rule",
                groupName: "materialInfoImport",
            },
            component: () =>
                import(
                    "@/views/storageManage/materialinfoImport/supplierBarcodeRuleManagement/index.vue"
                ),
        },
    ],
};
