import store from "@/store";

export default function permit(router) {
    router.beforeEach((to, from, next) => {
        const isLogin = store.getters["user/userInfo"];
        if (to.path == "/login") {
            if (isLogin) {
                store.dispatch({
                    type: "loginOut",
                });
            }
            next();
        } else {
            if (isLogin) {
                if (to.path == "/") {
                    if (!store.getters["app/sider"]?.menus.length) {
                        next("/403");
                    } else {
                        let firstPath = store.getters["app/sider"]?.firstPath;
                        let firstGroupCode = store.getters["app/sider"]?.firstGroupCode;
                        if (firstPath) {
                            store.commit("app/setSider", {
                                groupCode: firstGroupCode,
                                currentPath: firstPath,
                            });
                            next(firstPath);
                        } else {
                            next();
                        }
                    }
                } else {
                    next();
                }
            } else {
                next("/login");
            }
        }
    });
}
